// MessageCard.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LinkWithLanguage as Link } from '../component/LinkWithLanguage.jsx';
import LazyImage from '../component/LazyImage.jsx';
import StatefulImage from '../component/StatefulImage.jsx';
import HighlightSubstring from '../component/HighlightSubstring.jsx';
import Tooltip from '../component/Tooltip.jsx';
import UserAvatarWithStatus from '../container/UserAvatarWithStatus.js';

import withViewportItemTracker from '../resource/withViewportItemTracker.js';
import { MediaAssetFormat } from '../resource/getMediaAsset.js';
import getPublicSrcSet from '../resource/getPublicSrcSet.js';
import {
  LIVESTREAM_TYPE_LIVE_BADGE,
  ONLINE_TYPE_BIGGER_DOT,
} from '../resource/userStatusIndicatorConstants.js';
import { ButtonId } from '../resource/mixpanel.js';

import media from '../style/media.js';

import ShortsIconSource from '../../img/ic_short_white_fill.svg';
import CollectionIconSource from '../../img/ic_collection.svg';

// import LockIcon from '../../img/ic-lock-24.svg';

class MessageCard extends React.PureComponent {
  state = { isServer: true };
  nextTick = null;

  componentDidMount() {
    this.nextTick = setTimeout(() => {
      const { userId, username, fetchUser } = this.props;

      if (userId && !username) {
        fetchUser({ id: userId });
      }

      this.setState({ isServer: false });
    }, 0);
  }

  componentDidUpdate(prevProps) {
    const { userId: prevUserId, isAuthed: prevIsAuthed } = prevProps;
    const { userId, username, isAuthed, fetchUser } = this.props;

    if (isAuthed !== prevIsAuthed || userId !== prevUserId) {
      if (userId && !username) {
        fetchUser({ id: userId });
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.nextTick);
  }

  renderMessageThumbnail() {
    const {
      id,
      username,
      originalTitle,
      originalCaptionText,
      thumbnailSource,
      thumbnailSourceBlurred,
      shouldUseLazyThumbnail,
      hasVerified,
    } = this.props;
    if (!id) {
      return <Thumbnail />;
    }

    const displayCaption = `${originalTitle}${originalTitle ? '\n' : ''}${originalCaptionText}`;

    const [avifSrcSet, webpSrcSet, jpgSrcSet] = [
      MediaAssetFormat.AVIF,
      MediaAssetFormat.WEBP,
      MediaAssetFormat.JPG,
    ].map(format =>
      getPublicSrcSet({ href: thumbnailSource, size: 256, format })
    );
    const [avifSrcSetBlurred, webpSrcSetBlurred, jpgSrcSetBlurred] = [
      MediaAssetFormat.AVIF,
      MediaAssetFormat.WEBP,
      MediaAssetFormat.JPG,
    ].map(format =>
      getPublicSrcSet({ href: thumbnailSourceBlurred, size: 256, format })
    );

    const normalThumbnail = hasVerified ? (
      <Picture data-key="target">
        <source {...avifSrcSet} type="image/avif" />
        <source {...webpSrcSet} type="image/webp" />
        <img {...jpgSrcSet} alt={`${username} : ${displayCaption}`} />
      </Picture>
    ) : (
      <Picture data-key="target">
        <source {...avifSrcSetBlurred} type="image/avif" />
        <source {...webpSrcSetBlurred} type="image/webp" />
        <img {...jpgSrcSetBlurred} alt={`${username} : ${displayCaption}`} />
      </Picture>
    );
    const lazyThumbnail = hasVerified ? (
      <LazyImage
        data-key="target"
        {...jpgSrcSet}
        threshold={0.25}
        alt={`${username} : ${displayCaption}`}
      >
        <source {...avifSrcSet} type="image/avif" />
        <source {...webpSrcSet} type="image/webp" />
      </LazyImage>
    ) : (
      <LazyImage
        data-key="target"
        {...jpgSrcSetBlurred}
        threshold={0.25}
        alt={`${username} : ${displayCaption}`}
      >
        <source {...avifSrcSetBlurred} type="image/avif" />
        <source {...webpSrcSetBlurred} type="image/webp" />
      </LazyImage>
    );
    return (
      <Thumbnail>
        <StatefulImage>
          {shouldUseLazyThumbnail ? lazyThumbnail : normalThumbnail}
          <LazyImage data-key="error" {...jpgSrcSetBlurred} threshold={0.25}>
            <source {...avifSrcSetBlurred} type="image/avif" />
            <source {...webpSrcSetBlurred} type="image/webp" />
          </LazyImage>
        </StatefulImage>
      </Thumbnail>
    );
  }

  renderUsernameTooltip = () => {
    const { username } = this.props;

    return <TooltipWrapper>{username}</TooltipWrapper>;
  };

  renderMessageAvatar() {
    const { userId, enableTooltip } = this.props;

    return (
      <Tooltip
        position="bottom-center"
        renderHint={this.renderUsernameTooltip}
        trigger="hover"
        disabled={!enableTooltip}
      >
        <UserAvatarWithStatus
          id={userId}
          size={36}
          circleOffset={2}
          liveStreamType={LIVESTREAM_TYPE_LIVE_BADGE}
          livestreamSize={14}
          onlineType={ONLINE_TYPE_BIGGER_DOT}
          shouldShowOnline
          shouldShowLiveStream
        />
      </Tooltip>
    );
  }
  renderUser() {
    const { isServer } = this.state;
    const {
      userId,
      username,
      shouldShowUser: propsShouldShowUser,
      isShorts,
    } = this.props;
    const shouldShowUser = propsShouldShowUser && !isServer && !isShorts;

    return (
      shouldShowUser && (
        <AvatarWrapper>
          {userId ? (
            <AvatarBlock title={username}>
              {this.renderMessageAvatar()}
            </AvatarBlock>
          ) : (
            <LinkHolder>{this.renderMessageAvatar()}</LinkHolder>
          )}
        </AvatarWrapper>
      )
    );
  }
  renderShortsIcon = () => {
    const { isShorts } = this.props;
    if (!isShorts) {
      return null;
    }
    return <ShortsIcon src={ShortsIconSource} width="36" height="36" />;
  };
  renderMessagePackIcon = () => {
    const { hasPackBadge } = this.props;
    if (!hasPackBadge) {
      return null;
    }
    return (
      <MessagePackIcon src={CollectionIconSource} width="28" height="28" />
    );
  };
  render() {
    const {
      username,
      matchedString,
      originalTitle,
      originalCaptionText,
      cardLink,
      shouldWrapLink,
      shouldTrackEvent,
      shouldReplace,
      categoryIndex,
      categoryId,
      id,
    } = this.props;
    const { isServer } = this.state;
    // Keep MessageCard's link search equals to current page's link search
    // to ensure `state` (listPath) transmit to next story message page
    const search = isServer ? '' : window.location.search;
    const displayCaption = `${originalTitle}${originalTitle ? '\n' : ''}${originalCaptionText}`;

    return (
      <StyledMessageCard ref={el => (this.base = el)}>
        <ThumbnailLink
          replace={shouldReplace}
          to={{
            pathname: cardLink?.pathname || '',
            search,
          }}
          state={cardLink?.state || {}}
          title={`${username} : ${displayCaption}`}
          data-click_tracking_disabled={shouldTrackEvent ? false : true}
          data-element_id={ButtonId.All.ButtonStoryCard}
          data-tracking_payload={{
            messageId: id,
            'user.username': username,
            'discover.index': categoryIndex,
            'discover.category': categoryId,
          }}
          onClick={event => {
            if (!shouldWrapLink) return event.preventDefault();
          }}
        >
          {this.renderMessageThumbnail()}
        </ThumbnailLink>
        <Gradient />
        {this.renderUser()}
        <Caption>
          <HighlightSubstring substring={matchedString}>
            {displayCaption}
          </HighlightSubstring>
        </Caption>
        <Badges>
          {this.renderMessagePackIcon()}
          {this.renderShortsIcon()}
        </Badges>
      </StyledMessageCard>
    );
  }
}

MessageCard.propTypes = {
  id: PropTypes.string,
  categoryIndex: PropTypes.number,
  categoryId: PropTypes.string,
  matchedString: PropTypes.string,
  userId: PropTypes.string,
  username: PropTypes.string,
  originalTitle: PropTypes.string,
  originalCaptionText: PropTypes.string,
  thumbnailSource: PropTypes.string,
  thumbnailSourceBlurred: PropTypes.string,
  cardLink: PropTypes.any,
  shouldWrapLink: PropTypes.bool,
  shouldShowUser: PropTypes.bool,
  shouldTrackEvent: PropTypes.bool,
  shouldReplace: PropTypes.bool,
  enableTooltip: PropTypes.bool,
  shouldUseLazyThumbnail: PropTypes.bool,
  isAuthed: PropTypes.bool,
  isShorts: PropTypes.bool,
  hasPackBadge: PropTypes.bool,
  hasVerified: PropTypes.bool,
  fetchUser: PropTypes.func,
};

MessageCard.defaultProps = {
  id: '',
  categoryIndex: null,
  categoryId: null,
  matchedString: '',
  userId: null,
  username: '',
  originalTitle: '',
  originalCaptionText: '',
  thumbnailSource: '',
  thumbnailSourceBlurred: '',
  cardLink: null,
  shouldWrapLink: false,
  shouldShowUser: false,
  shouldTrackEvent: false,
  shouldReplace: false,
  enableTooltip: false,
  shouldUseLazyThumbnail: true,
  isAuthed: false,
  isShorts: false,
  hasPackBadge: false,
  hasVerified: false,
  fetchUser: () => null,
};

const StyledMessageCard = styled.section`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  width: 100%;
  height: 100%;
  background: none;
  overflow: hidden;
`;

const Gradient = styled.div`
  grid-column: 1;
  grid-row: 1 / span 2;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.05) 17%,
    rgba(0, 0, 0, 0.3) 53%,
    rgba(0, 0, 0, 0.5)
  );
  z-index: 1;
  pointer-events: none;
  border-radius: 4px;
  overflow: hidden;
`;

const Thumbnail = styled.div`
  z-index: 1;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  background-color: #646464;
  overflow: hidden;
`;

const Picture = styled.picture`
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const LinkHolder = styled.div`
  display: block;
  height: 100%;
`;

const AvatarWrapper = styled.div`
  grid-row: 1;
  grid-column: 1;
  margin: 6px 0 0 6px;
  width: 36px;
  height: 36px;
  position: relative;
  z-index: 1;
`;
const AvatarBlock = styled.div`
  display: block;
  :hover {
    opacity: 1;
  }
`;

const TooltipWrapper = styled.div`
  margin-top: 2px;
  padding: 4px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: rgb(246, 246, 246, 0.8);
  color: #191919;
  font-size: 12px;
  line-height: 1;
`;

const Caption = styled.h2`
  margin: 0;
  margin-bottom: 4px;
  position: relative;
  grid-column: 1;
  grid-row: 2;
  padding: 4px 4px 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  display: -webkit-box;
  word-break: break-all;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  z-index: 1;
  pointer-events: none;
  ${media.tablet`
    font-size: 14px;
  `};
`;

const ThumbnailLink = styled(Link)`
  display: block;
  grid-column: 1;
  grid-row: 1 / span 2;
`;

const Badges = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const BadgeIcon = styled.img`
  margin-left: 4px;
  object-fit: contain;
  :first-child {
    margin-left: 0px;
  }
`;

const ShortsIcon = styled(BadgeIcon)`
  width: 36px;
  height: 36px;
  ${media.tablet`
    width: 28px;
    height: 28px;
  `};
`;

const MessagePackIcon = styled(BadgeIcon)`
  width: 28px;
  height: 28px;
`;

export default withViewportItemTracker(MessageCard);
